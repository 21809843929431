import React, {useContext} from "react";
import "./Contact.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import {illustration, contactInfo, isHireable} from "../../portfolio";
import {Fade} from "react-reveal";
import email from "../../assets/lottie/email";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";

export default function Contact() {
  const {isDark} = useContext(StyleContext);

  let hireable;
  if (isHireable) {
    hireable = "Yes";
  } else {
    hireable = "No";
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">{contactInfo.title}</h1>
            <div class="main-content-profile">
              <div class="blog-header">
                <p class="subTitle blog-subtitle">
                  Discuss a project or just want to say hi? My Inbox is open for
                  all.
                </p>
              </div>
              <h2 class="bio-text"> Senior Software Engineer @Tkxel</h2>
              <div class="location-div">
                <span class="desc-prof">
                  <svg
                    viewBox="-0.5 -2 20 19"
                    version="1.1"
                    width="22"
                    height="16"
                    aria-hidden="true"
                    stroke="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 0C2.69 0 0 2.5 0 5.5 0 10.02 6 16 6 16s6-5.98 6-10.5C12 2.5 9.31 0 6 0zm0 14.55C4.14 12.52 1 8.44 1 5.5 1 3.02 3.25 1 6 1c1.34 0 2.61.48 3.56 1.36.92.86 1.44 1.97 1.44 3.14 0 2.94-3.14 7.02-5 9.05zM8 5.5c0 1.11-.89 2-2 2-1.11 0-2-.89-2-2 0-1.11.89-2 2-2 1.11 0 2 .89 2 2z"
                    ></path>
                  </svg>
                  Lahore, Pakistan
                </span>
              </div>
              {/* <br /> */}

              <div class="opp-div">
                <span class="desc-prof">
                  Open for opportunities: {hireable}
                </span>
              </div>
            </div>
            <br />
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.number && (
                <>
                  <a
                    className="contact-detail"
                    href={"tel:" + contactInfo.number}
                  >
                    {contactInfo.number}
                  </a>
                  <br />
                  <br />
                </>
              )}

              <SocialMedia />
            </div>
          </div>
          <div className="contact-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={email} />
            ) : (
              <img
                alt="Man working"
                // src={require("https://avatars.githubusercontent.com/u/umer-nazir02")}
                src={"https://avatars.githubusercontent.com/u/umer-nazir02"}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
